import { useFormikContext } from "formik";
import { components } from "../../../api/schema";
import FormSwitch from "../../../components/formComponents/FormSwitch";
import FormTitleAndDescription from "../../../components/formComponents/FormTitleAndDescription";
import { shouldDisableDefaultPolicyInputs } from "../utils";

interface Props {
  isCustomizedPolicy?: boolean;
  defaultPolicy?: components["schemas"]["V1alpha1Policy"] | undefined;
}

const ZeroDowntimeRollout = ({ isCustomizedPolicy }: Props) => {
  const { values } = useFormikContext<{
    allowRollingUpdate: boolean;
  }>();
  return (
    <div className="flex flex-col gap-4 py-10 border-b border-strongBorder">
      <FormTitleAndDescription
        title="Zero downtime rollout strategy"
        description="Optimize workloads by first creating new optimized pods to ensure high availability with no-downtime, while respecting workload’s strategy."
        tooltipMaxWidth={550}
      />

      <div className="flex flex-col gap-1">
        <FormSwitch
          name="allowRollingUpdate"
          disabled={!isCustomizedPolicy && shouldDisableDefaultPolicyInputs}
          disableChange={!isCustomizedPolicy}
          label="Workloads with a single replica"
        />
        <FormSwitch
          name="considerDeploymentStrategy"
          disabled={(!isCustomizedPolicy && shouldDisableDefaultPolicyInputs) || !values.allowRollingUpdate}
          disableChange={!isCustomizedPolicy}
          label="Deployments with multiple replicas and a rollout strategy that restrict pod scale down"
          info={<>With restriction of 10 replicas</>}
        />
      </div>
    </div>
  );
};
export default ZeroDowntimeRollout;
